<template>
  <div>
    <Modal :options="{width: '40vw', type: 'danger'}" @close="$emit('close')">
      <div class="title">Eliminar Documento</div>
      <div class="body">
        
        <p class="text-center">Estas por eliminar el documento:
            <strong>{{ eliminar_archivo.nombre_original }}</strong>,
            esta acción no puede deshacerse.<br/>¿Deseas continuar?
         </p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="eliminar">Confirmar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">

import Modal from '@/components/Modal'
import apiClientes from '@/apps/clientes/api/clientes';

  export default {
    components: {
      Modal
    }
    ,props: {
      eliminar_archivo:null,
    }
    ,methods: {
      async eliminar() {
        try {
            await apiClientes.eliminar_documento_tickets(this.eliminar_archivo.id).data;
            this.$emit('actualizar');
            this.$emit('close');
            this.$helper.showMessage('Documento Eliminado','El documento ha sido eliminado con éxito','success');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    },
  }
</script>